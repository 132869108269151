<template>
  <div class="role-main">
<!--    <div class="top">-->
<!--      <Button type="primary" @click="add">{{$t('dataset_trainuser_add')}}</Button>-->
<!--    </div>-->
    <MyBreadcrumb></MyBreadcrumb>

    <div class="role-main-cont">
<!--      <div class="role-main-top">-->
<!--        <div>-->
<!--          <span :class="tabList[curTabIndex].id == item.id ? 'active' : ''" v-for="(item,index) in tabList" :key="item.id" @click="changeTab(index)">{{ item.name }}</span>-->
<!--        </div>-->
<!--      </div>-->
      <div class="search" >
        <div class="search-item">
          <div class="name">
            分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="(item,fIndex) in categoryList" :key="item.id + 'category'" @click="changeSearch(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="curTabIndex == 0">
        <el-table :data="data" border size="small" v-loading="loading" :header-cell-style="headerCellStyle">
          <el-table-column label="ID" prop="id" width="80" fixed="left"></el-table-column>
          <el-table-column label="标题" prop="train.name" fixed="left" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column label="分类" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ row.train.generate_type == 0?row.train?.generate_category?.name:row.train.generate_type == 1?row?.train.generate_map?.name:row?.train.generate_category?.name }}
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template v-slot='{row}'>
              {{ typeObj[row.train.type] }}
            </template>
          </el-table-column>
          <el-table-column label="用时" prop="use_time"></el-table-column>
          <el-table-column label="练习数" prop="have_finished"></el-table-column>
          <el-table-column label="答对数" prop="right_count"></el-table-column>
          <el-table-column label="创建时间" min-width="120" show-overflow-tooltip>
            <template v-slot='{row}'>
              {{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="260" fixed="right">
            <template v-slot='{row}'>
              <el-button type="primary" size="mini" @click="check(row)">查看</el-button>
              <el-button type="primary" size="mini" @click="toReport(row)">查看报告</el-button>
              <el-button type="primary" size="mini" @click="goTrain(row)">继续刷题</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <Table border :columns="columns" :loading="loading" :data="data"></Table> -->
        <div class="page">
<!--          <Page :total="total" @on-change="changePage" :current="page" show-total show-sizer show-elevator></Page>-->
          <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
        </div>

      </div>
<!--      <div v-else>-->
<!--        <Table border :columns="columnsRecord" :loading="recordLoading" :data="recordData"></Table>-->
<!--        <div class="page">-->
<!--          <Page :total="totalRecord" @on-change="changeRecordPage" @on-page-size-change="changeRecordPageSize" :current="recordPage" show-total show-sizer show-elevator></Page>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <DeleModal :status="deleModalStatus" :txt="$t('dataset_trainuser_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
  </div>
</template>

<script>
import DeleModal from '../../components/deleteModal.vue';
import util from '@/utils/tools.js';
export default {
  name: "role",
  data(){
    return{
      util,
      tabList:[
        {
          name:'练习记录',
          id:1,
          tab:'tarinList'
        },
        {
          name:'模考记录',
          id:2,
          tab:'examList'
        },
      ],
      headerCellStyle:{
        backgroundColor: "#F8F8F9",
        color: "#666",
        fontWeight: "bold"
      },
      curTabIndex:0,
      // columns: [
      //   {
      //     title: this.$t('dataset_trainuser_table_index'),
      //     key:'id',
      //     render: (h, para) => {
      //       return h('span', para.row.id );
      //     }
      //   },
      //   {
      //     title: this.$t('dataset_trainuser_table_title'),
      //     key: 'title',
      //     render: (h, para) => {
      //       return h('span', para.row.train.name );
      //     }
      //   },
      //   {
      //     title: this.$t('dataset_trainuser_table_category'),
      //     key: 'title',
      //     render: (h, para) => {
      //       var name;
      //       if (para.row.train.generate_type == 0){
      //         name = para.row.train.generate_category ? para.row.train.generate_category.name : '';
      //       }else if (para.row.train.generate_type == 1){
      //         name = para.row.train.generate_map ? para.row.train.generate_map.name : '';
      //       }else{
      //         name = para.row.train.generate_category ? para.row.train.generate_category.name : '';
      //       }
      //       return h('span',  name);
      //     }
      //   },
      //   {
      //     title: this.$t('dataset_trainuser_table_type'),
      //     key: 'type',
      //     render: (h, para) => {
      //       return h('span', this.typeObj[para.row.train.type]);
      //     }
      //   },
      //   {
      //     title: this.$t('dataset_trainuser_table_use_time'),
      //     key: 'use_time',
      //   },
      //   {
      //     title: this.$t('dataset_trainuser_table_have_finished'),
      //     key: 'have_finished',
      //   },
      //   {
      //     title: this.$t('dataset_trainuser_table_right_count'),
      //     key: 'right_count',
      //   },
      //   {
      //     title: this.$t('dataset_trainuser_table_created_at'),
      //     key: 'created_at',
      //     render: (h, para) => {
      //       return h('span', util.timeFormatter(
      //               new Date(+para.row.created_at * 1000), 'yyyy-MM-dd hh:mm'
      //           )
      //       );
      //     }
      //   },
      //   {
      //     title: this.$t('dataset_trainuser_table_op'),
      //     render: (h, params) => {
      //       return h('div', [
      //         h('Button', {
      //           props: {
      //             type: 'primary',
      //             size: 'small'
      //           },
      //           style: {
      //             marginRight: '5px'
      //           },
      //           on: {
      //             click: () => {
      //               this.check(params.row)
      //             }
      //           }
      //         }, this.$t('system_check')),
      //         h('Button', {
      //           props: {
      //             type: 'primary',
      //             size: 'small'
      //           },
      //           style: {
      //             marginRight: '5px'
      //           },
      //           on: {
      //             click: () => {
      //               this.toReport(params.row)
      //             }
      //           }
      //         }, '查看报告'),
      //         h('Button', {
      //           props: {
      //             type: 'primary',
      //             size: 'small'
      //           },
      //           on: {
      //             click: () => {
      //               this.goTrain(params.row)
      //             }
      //           }
      //         }, this.$t('trainingcamp_mytrain_continue'))
      //       ]);
      //     }
      //   }
      // ],
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      total:0,
      modalStatus:false,
      formItem:{
        id:'',
        name:'',
        data_count:'',
        desc:'',
      },
      ruleValidate: {
        id: [
          { required: true, message: this.$t('dataset_trainuser_add_id_tip'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('dataset_trainuser_add_name_tip'), trigger: 'blur' }
        ],
        data_count: [
          { message: this.$t('dataset_trainuser_add_data_count_tip'), trigger: 'blur' }
        ],
        desc: [
          { message: this.$t('dataset_trainuser_add_desc_tip'), trigger: 'blur' },
        ],
      },
      addBtnLoading:false,
      type:'',
      modalTit:'',
      typeObj:{},
      // columnsRecord:[
      //   {
      //     title: this.$t('dataset_trainuser_table_index'),
      //     key:'id',
      //   },
      //   {
      //     title: this.$t('trainingcamp_myexam_name'),
      //     key:'name',
      //     minWidth:60,
      //     render: (h, para) => {
      //       return h('router-link', {
      //         props: {
      //           to: {
      //             path: '/trainingcamp/exam-detail',
      //             query: {
      //               id: para.row.train.id,
      //               tab: 'result'
      //             }
      //           }
      //         },
      //         style:{
      //           color:'#2db7f5',
      //         }
      //       }, para.row.train.name);
      //     }
      //   },
      //   {
      //     title: this.$t('trainingcamp_exam_list_generate_type'),
      //     key: 'generate_type',
      //     render: (h, params) => {
      //       return h('span', this.generatetypesObj[params.row.train.generate_type]);
      //     }
      //   },
      //   {
      //     title: this.$t('trainingcamp_exam_list_generate_id'),
      //     key: 'generate_type',
      //     render: (h, params) => {
      //       if (params.row.train.generate_type == 0){
      //         return h('span', params.row.train.generate_category.name);
      //       }else if (params.row.train.generate_type == 1){
      //         return h('span', params.row.train.generate_map.name);
      //       }else if (params.row.train.generate_type == 2){
      //         return h('span', params.row.train.generate_group.name);
      //       }
      //
      //     }
      //   },
      //   {
      //     title: this.$t('trainingcamp_myexam_total_count'),
      //     key:'data_count',
      //     render: (h, para) => {
      //       return h('span', para.row.train.data_count);
      //     }
      //   },
      //   {
      //     title: '总分',
      //     render: (h, para) => {
      //       return h('span', para.row.train.total_score);
      //     }
      //   },
      //   {
      //     title: '客观题分数',
      //     key: 'auto_check_score'
      //   },
      //   {
      //     title: '主观题分数',
      //     key: 'hand_check_score'
      //   },
      //   {
      //     title: '及格分',
      //     render: (h, para) => {
      //       return h('span', para.row.train.pass_score);
      //     }
      //   },
      //   {
      //     title: this.$t('trainingcamp_myexam_finish_count'),
      //     key:'have_finished',
      //     render: (h, para) => {
      //       return h('span', para.row.have_finished);
      //     }
      //   },
      //   {
      //     title: this.$t('trainingcamp_myexam_right_count'),
      //     key:'right_count',
      //     render: (h, para) => {
      //       return h('span', para.row.right_count);
      //     }
      //   },
      //   {
      //     title: this.$t('trainingcamp_myexam_score'),
      //     key:'score',
      //   },
      //   {
      //     title: this.$t('trainingcamp_myexam_use_time'),
      //     key:'use_time',
      //   },
      //   {
      //     title: this.$t('trainingcamp_myexam_record_status'),
      //     key:'status',
      //     render: (h, para) => {
      //       if (this.trainUserStatuses){
      //         return h('span', this.trainUserStatuses[para.row.status]);
      //       }
      //
      //     }
      //   },
      //   {
      //     title: this.$t('trainingcamp_myexam_record_finish_time'),
      //     key: 'finish_time',
      //     render: (h, para) => {
      //       return h('span', util.timeFormatter(
      //               new Date(+para.row.finish_time * 1000), 'yyyy-MM-dd hh:mm'
      //           )
      //       );
      //     }
      //   },
      //   {
      //     title: this.$t('dataset_trainuser_table_op'),
      //     render: (h, params) => {
      //       return h('div', [
      //         h('Button', {
      //           props: {
      //             type: 'primary',
      //             size: 'small',
      //           },
      //           style: {
      //             marginRight: '5px'
      //           },
      //           on: {
      //             click: () => {
      //               this.checkResult(params.row)
      //             }
      //           }
      //         }, this.$t('trainingcamp_myexam_record_check_result')),
      //         h('Button', {
      //           props: {
      //             type: 'primary',
      //             size: 'small'
      //           },
      //           style: {
      //             marginRight: '5px'
      //           },
      //           on: {
      //             click: () => {
      //               this.toReport(params.row)
      //             }
      //           }
      //         }, '查看报告'),
      //       ]);
      //     }
      //   }
      // ],
      // recordData:[],
      // recordLoading:false,
      // totalRecord:0,
      // recordPage:1,
      // recordPageSize:10,
      tabName:'',
      trainUserStatuses:{},
      generatetypesObj:{},
      categoryList:[],
      curCategoryId:'0',
    }
  },
  components:{
    DeleModal
  },
  created(){
    console.log(this.$route.params,'this.$router.params')
    this.tabName = this.$route.params.tab || 'tarinList';
    console.log(this.tabName,'this.tabName')
    if(this.tabName == 'tarinList'){
      this.curTabIndex = 0;
      this.getList();
    }else{
      this.curTabIndex = 1;
      this.getRecordList();
    }
  },
  mounted(){

  },
  methods:{
    changeTab(index){
      this.$router.push({
        name:'my-train',
        params:{
          tab:this.tabList[index].tab
        }
      })
      this.curTabIndex = index;
      if(this.tabList[index].tab == 'tarinList'){
        this.getList();
      }else{
        this.getRecordList();
      }
    },
    add(){
      this.modalTit = this.$t('dataset_trainuser_add');
      this.type = '';
      this.formItem = {
        id:'',
        name:'',
        data_count:'',
        desc:'',
      };
      this.modalStatus = true;
    },
    check(data){
      this.$router.push({
        path:'/trainingcamp/train-result',
        query:{
          id:data.id
        }
      })
    },
    toReport(data){
      this.$router.push({
        path:'/trainingcamp/train-report',
        query:{
          id:data.id
        }
      })
    },
    edit(data){
      this.modalTit = this.$t('dataset_trainuser_edit');
      this.type = 'edit';
      this.formItem = {
        id:data.id,
        name:data.name,
        data_count:data.data_count,
        desc:data.desc
      };
      this.modalStatus = true;
    },
    getList(){
      this.loading = true;
      let params = {
        action:'myGroupRecordList',
        page:this.page,
        pageSize:this.pageSize,
        category_id:this.curCategoryId
      };
      this.api.dataset.trainExecute(params).then((res)=>{

        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.typeObj = res.types;
        this.categoryList =[
          {
            name:'全部',
            id:0
          },
            ...res.category_list
        ]
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeSearch(data){
      this.curCategoryId = data.id;
      this.getList();
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        name:this.curDeleData.name
      }
      this.modalLoading = true;
      this.api.dataset.trainuserDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    ok(){
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          let data = {
            id:this.formItem.id,
            data_count:this.formItem.data_count,
            name:this.formItem.name,
            desc:this.formItem.desc
          };
          this.addBtnLoading = true;
          if(this.formItem.id){
            this.api.dataset.trainuserUpdate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.getList();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }else{
            this.api.dataset.trainuserCreate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.page = 1;
              this.getList();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }

        }else{
          console.log(this.modalStatus)
        }
      })
    },
    cancel(){
      this.modalStatus = false;
      this.$refs.formCustom.resetFields();
    },
    goTrain(data){
      if(data.train.from_type == 5){
        this.dataBurialPoint(data);
      }

      this.$router.push({
        path:'/trainingcamp/trainDetail',
        query:{
          train_id:data.train_id,
          train_user_id:data.id,
          product_id:'2',
        }
      })
    },
    dataBurialPoint(data){
      this.api.dataset.dataBurialPoint({
        course_id:data.train.generate_course.id,
        source_type:'data',
        map_id:data.train.generate_course.map_id,
        node_id:data.train.generate_id,
        source_id:data.train.generate_id
      }).then(res=>{
        console.log(res,"数据埋点");
      })
    },
    // getRecordList(){
    //   this.recordLoading = true;
    //   let params = {
    //     action:'myTrainList',
    //     page:this.recordPage,
    //     pageSize:this.recordPageSize,
    //     type:1
    //   };
    //   this.api.dataset.trainExecute(params).then((res)=>{
    //
    //     this.recordLoading = false;
    //     if(!res.list.length && this.recordPage > 1){
    //       this.recordPage = this.recordPage - 1;
    //       this.getRecordList();
    //       return;
    //     }
    //     this.recordData = res.list;
    //     this.totalRecord = Number(res.count);
    //     this.trainUserStatuses = res.trainUserStatuses;//trainuser.status
    //     this.generatetypesObj = res.generate_types;
    //   }).catch((err)=>{
    //     this.recordLoading = false;
    //   })
    // },
    // changeRecordPage(page){
    //   this.recordPage = page;
    //   this.getRecordList();
    // },
    // changeRecordPageSize(pageSize){
    //   this.recordPageSize = pageSize;
    //   this.getRecordList();
    // },
    // checkResult(data){
    //   this.$router.push({
    //     path:'/trainingcamp/exam',
    //     query:{
    //       id:data.train_id || data.id,
    //       isResult:true,
    //       isSelf:true
    //     }
    //   })
    // }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  .role-main-breadcrumb{
    margin-bottom: 20px;
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
  }
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .role-main-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
  }
  .role-main-top{
    margin-bottom: 20px;
    >div{
      display: inline-block;
      height: 48px;
      line-height: 48px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      >span{
        display: inline-block;
        width: 100px;
        text-align: center;
        border-right: 1px solid #dcdee2;
        cursor: pointer;
      }
      >span:last-child{
        border-right:none;
      }
      .active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
  }
  .top{
    text-align: left;
    margin-bottom: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
